exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */),
  "component---src-pages-troubleshoot-js": () => import("./../../../src/pages/troubleshoot.js" /* webpackChunkName: "component---src-pages-troubleshoot-js" */),
  "component---src-templates-simple-footer-page-js": () => import("./../../../src/templates/simple-footer-page.js" /* webpackChunkName: "component---src-templates-simple-footer-page-js" */)
}

